@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    --fa-primary-color: #026395;
    --fa-secondary-color: #f5f5f5;
    --fa-primary-opacity: 1;
    --fa-secondary-opacity: 1;

    @apply [--header-height:66px] motion-safe:scroll-smooth md:[--header-height:66px];
  }
}

@layer utilities {
  .text-container {
    @apply container lg:max-w-screen-lg;
  }

  .text-container > p {
    @apply mb-4 last:mb-0;
  }

  .text-shadow {
    text-shadow: 0.2rem 0.2rem 0.6rem #c0c0c0;
  }

  .scrollbar-none::-webkit-scrollbar {
    display: none;
  }

  .scrollbar-none {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}
